import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from '@/store'
import i18n from '@/i18n'

const store = useStore()

const routes = [
  {
    path: '/',
    name: 'stub',
    component: () => import('../views/MainView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/MainView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async to => {
  const { config } = await store.dispatch('main/getConfig')

  setFavicon(config.head_favicon)
  setPropertyColors(config.colors)
  seti18nParams(config.local, to.query)
  setOtherProperty(config)
  preloadImages(config)
})

const preloadImages = config => {
  const { t } = i18n.global

  const body = JSON.parse(config.body || '{}')

  Object.values(body).forEach(element => {
    if (element.type === 'img') {
      const src = t(`body.${element.href}`)

      if (src) {
        const img = new Image()
        img.src = src
      }
    }
  })

  const logoImg = new Image()
  logoImg.src = config.logo_pic
}

const seti18nParams = (local, query) => {
  const locales = JSON.parse(local || '{}')
  const countryCodes = Object.keys(locales)

  i18n.global.fallbackLocale.value = countryCodes[0]

  Object.entries(locales).forEach(([countryCode, messages]) => {
    i18n.global.setLocaleMessage(countryCode, messages)
  })

  // Language detection by get parameter (lang=en)
  if (query.lang) {
    if (countryCodes.includes(query.lang)) {
      i18n.global.locale.value = query.lang
      return
    }
  }

  // Language detection by browser language
  let browserLanguage = navigator.language || navigator.userLanguage
  browserLanguage = browserLanguage.split('-')[0]

  if (countryCodes.includes(browserLanguage)) {
    i18n.global.locale.value = browserLanguage
    return
  }

  // Setting the default language first in the locale object
  i18n.global.locale.value = countryCodes[0]
}

const setFavicon = faviconUrl => {
  let link = document.querySelector("link[rel*='icon']")

  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.head.appendChild(link)
  }

  link.href = faviconUrl
}

const setPropertyColors = configColors => {
  const colors = JSON.parse(configColors) || {}

  setDefaultThemeColor(colors?.background)

  Object.entries(colors).forEach(([key, color]) => {
    document.body.style.setProperty(`--${key}`, color)
  })
}

const setDefaultThemeColor = color => {
  let themeLink = document.querySelector("meta[name*='theme-color']")

  if (!themeLink) {
    themeLink = document.createElement('meta')
    themeLink.name = 'theme-color'
    document.head.appendChild(themeLink)
  }

  themeLink.content = color
}

const setOtherProperty = config => {
  document.body.style.setProperty('--background_pic', `url(${config.background_pic})`)
}

export default router
