<template>
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: 'Montserrat';
  src: url('@/assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('@/assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  color: var(--font_main);
  background: var(--background_pic);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  background-color: var(--background);
  background-attachment: fixed;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 300;

  a {
    color: inherit;
    text-decoration: inherit;
  }
}

@media (max-width: 768px) {
  body {
    background: none;
    background-color: var(--background);
  }
}
</style>
