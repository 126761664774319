import { config } from '../index'

const state = () => {
  return {
    config: null
  }
}

const actions = {
  async getConfig({ commit }) {
    let isLocalConfig = true

    try {
      const localConfig = require(`../../../public/domains/${window.location.hostname}/config.json`)
      if (localConfig) {
        commit('SET_CONFIG', localConfig)
        return localConfig
      }
    } catch (e) {
      isLocalConfig = false
    }

    if (isLocalConfig) {
      return
    }

    try {
      const response = await config.get()
      commit('SET_CONFIG', response.data)
      return response.data
    } catch (e) {
      console.error(e)
    }
  }
}

// mutations
const mutations = {
  ['SET_CONFIG'](state, config) {
    state.config = config
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
